import React, { useState, useEffect } from 'react';
import AsyncImageFromURL from './AsyncImageFromURL';
import getBaseWordPressURL from '../WordPressURLManager';

const domainUrl = `${getBaseWordPressURL()}media/`;

const AsyncImageLoader = ({ post }) => {
  const [feedData, setFeedData] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        let imageURL = domainUrl + post
        const response = await fetch(imageURL);
        const data = await response.json();
        setFeedData(data);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchConfig();
  }, [post]);
  
  if (!feedData) {
    return <div class="react-loading-notice">Loading Image...</div>;
  }
  let image_url = feedData.source_url

  return <AsyncImageFromURL url={image_url} />;
};

export default AsyncImageLoader;