import React from 'react';

const ImageFeature = ({ url }) => {
    return (
      <img alt="async featured item"
        className="async-featured-image"
        src={url}
      />
  );
};

export default ImageFeature;