import React from 'react';
import AsyncImageLoader from '../async_images/AsyncImageLoader';

const ImageFeature = ({ post }) => {
    return (
      <article id="featured-image" class="et_pb_post clearfix et_pb_no_thumb et_pb_blog_item_0_0 post-16988 featured-image-wrapper status-publish hentry">
        <AsyncImageLoader post={post.featured_media} />
        <div className="image-caption">
            <span dangerouslySetInnerHTML={{__html: post.content.rendered }} />
        </div>
      </article>
  );
};

export default ImageFeature;