import React, { useState } from 'react';
// import _ from 'lodash';
  
const ClubMap = ( { searchMethod } ) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (e) => {
    console.log("handing_change")
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    console.log("Search Query:", searchQuery);
    searchMethod(searchQuery);
  };

  return (
    <div className="clubSearchBar" style={{ textAlign: "right"}}>
      <label style={{ marginRight: "10px" }}>Search</label>
      <div className="searchInputWrapper" style={{ border: "1px solid #aaaaaa", marginBottom: "5px", display: "inline-block" }}>
      <input
          type="text"
          className="float-right"
          style={{ fontSize: "16px", padding: "4px", margin: "4px", border: "none" }}
          value={searchQuery} // Bind input value to state
          onChange={handleInputChange} // Update state on input change
        />
      </div>
      <button
        style={{
          border: "1px solid #81afa1",
          fontSize: "16px",
          color: "#ffffff",
          backgroundColor: "#81afa1",
          padding: "7px",
          marginLeft: "10px",
        }}
        onClick={handleSearch} // Trigger function on button click
      >SEARCH</button>
    </div>
  )
};

export default ClubMap;