import _ from 'lodash';
import ImageFeature from './ImageFeature'
import { languageInflectTitle } from '../language_inflection';

const pageLanguage = document.documentElement.lang;

function FeaturedImage({ feedData }) {
  return (
    <>
    <div class="et_pb_module et_pb_heading et_pb_heading_0 featured-image et_pb_bg_layout_">
        <div class="et_pb_heading_container">
            <h1 class="et_pb_module_heading">{ languageInflectTitle("Photo of the Week", pageLanguage) }</h1>
        </div>
    </div>
    <div class="et_pb_module et_pb_blog_0 featured-imager et_pb_posts et_pb_bg_layout_light ">
      <div class="et_pb_ajax_pagination_container">
        <ImageFeature post={_.first(feedData, 1)} />
      </div>
  </div>
  </>
  )
}

export default FeaturedImage;