import React, { useState, useEffect } from 'react';
import FeaturedImage from './FeaturedImage';
import getBaseWordPressURL from '../WordPressURLManager';
import { languageInflectPhotosURL } from '../language_inflection';

const pageLanguage = document.documentElement.lang;

const domainUrl = `${getBaseWordPressURL()}posts?${languageInflectPhotosURL(pageLanguage)}`;

const FeaturedImageFeed = () => {
  const [feedData, setFeedData] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(domainUrl);
        const data = await response.json();
        setFeedData(data);
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    };

    fetchConfig();
  }, []);
  
  if (!feedData) {
    return <div class="react-loading-notice">Loading Image...</div>;
  }

  return <FeaturedImage feedData={feedData} />;
};

export default FeaturedImageFeed;