const languageInflectURL = (lang) => {
  let opts = {
    "en-US": "lang=en",
    "de-DE": "lang=de",
    "es-ES": "lang=es",
    "fr-FR": "lang=fr",
    "ja":    "lang=ja",
    "pt-br": "lang=pt-br"
  }
  return opts[lang] || opts["en-US"];
}

const languageInflectPhotosURL = (lang) => {
  let opts = {
    "en-US": "categories=162&lang=en",
    "de-DE": "categories=184&lang=de",
    "es-ES": "categories=188&lang=es",
    "fr-FR": "categories=192&lang=fr",
    "ja":    "categories=194&lang=ja",
    "pt-br": "categories=191&lang=pt-br"
  }
  return opts[lang] || opts["en-US"];
}

const languageInflectTitle = (title, lang) => {
  if (title === "Happening Now") {
    let opts = {
      "en-US": "Happening Now",
      "de-DE": "Aktuelles",
      "es-ES": "Pasando Ahora",
      "fr-FR": "En Ce Moment",
      "ja":    "今起こっていること",
      "pt-br": "Acontecendo agora",
      "ru": "Происходит сейчас"
    }
    return opts[lang] || opts["en-US"];
  }
  if (title === "Story Tags") {
    let opts = {
      "en-US": "Story Tags",
      "de-DE": "Schlagwörter",
      "es-ES": "Etiquetas",
      "fr-FR": "Mots clés",
      "ja":    "タグ",
      "pt-br": "Etiquetas",
      "ru":    "Теги"
    }
    return opts[lang] || opts["en-US"];
  }
  if (title === "Photo of the Week") {
    let opts = {
      "en-US": "Photo of the Week",
      "de-DE": "Foto der Woche",
      "es-ES": "Foto de la Semana",
      "fr-FR": "Photo de la Semaine",
      "ja":    "今週の写真",
      "pt-br": "Foto da Semana",
      "ru":    "Фото недели"
    }
    return opts[lang] || opts["en-US"];
  }
}

export { languageInflectURL, languageInflectTitle, languageInflectPhotosURL };