const getBaseMyURL = () => {
  if (process.env.REACT_APP_BACKEND_RAILS_URL) {
    return process.env.REACT_APP_BACKEND_RAILS_URL
  }
  if (window.location.href.includes("staging") || window.location.href.includes("localhost")) {
    return "https://mystaging.friendshipforce.org/api/v1/"
  } else {
    return "https://my.friendshipforce.org/api/v1/"
  }
}

export default getBaseMyURL;