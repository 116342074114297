import React, { useState, useEffect } from 'react';
import HappeningFeed from './HappeningFeed';
import getBaseWordPressURL from '../WordPressURLManager';
import { languageInflectURL } from '../language_inflection';

const pageLanguage = document.documentElement.lang;
const domainUrl = `${getBaseWordPressURL()}happening?per_page=3&${languageInflectURL(pageLanguage)}`;

const HappeningNow = () => {
  const [feedData, setFeedData] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(domainUrl);
        const data = await response.json();
        setFeedData(data);
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    };

    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on component load

  if (!feedData) {
    return <div class="react-loading-notice">Loading the news...</div>;
  }

  return <HappeningFeed feedData={feedData} />;
};

export default HappeningNow;