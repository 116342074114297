import React, { useState, useEffect } from 'react';
import TagsDisplay from './TagsDisplay';
import getBaseWordPressURL from '../WordPressURLManager';
import { languageInflectURL } from '../language_inflection';

const pageLanguage = document.documentElement.lang;

const tagsUrl = `${getBaseWordPressURL()}tags?hide_empty=true&per_page=100&${languageInflectURL(pageLanguage)}`;

const TagCloud = () => {
  const [tagsData, setTagsData] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(tagsUrl);
        const data = await response.json();
        setTagsData(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on component load

  if (!tagsData) {
    return <div class="react-loading-notice">Loading Tags...</div>;
  }

  return <TagsDisplay tagsData={tagsData} />;
};

export default TagCloud;