const getBaseWordPressURL = () => {
  if (process.env.REACT_APP_BACKEND_URL) {
    return process.env.REACT_APP_BACKEND_URL
  }
  if (window.location.href.includes("staging") || window.location.href.includes("localhost")) {
    return "https://ffi-new-main.flywheelstaging.com/wp-json/wp/v2/"
  } else {
    return "https://friendshipforce.org/wp-json/wp/v2/"
  }
};

export default getBaseWordPressURL;