import React from 'react';

const Post = ({ post }) => {
    return (
      <article id="post-16988" class="et_pb_post clearfix et_pb_no_thumb et_pb_blog_item_0_0 post-16988 happening type-happening status-publish hentry">
      <h2 class="entry-title">
          <a target="_blank" rel="noreferrer" href={post.acf.url_address}>{ post.title.rendered }</a>
      </h2>
      <div class="post-content">
          <div class="post-content-inner et_pb_blog_show_content">
              <a target="_blank" rel="noreferrer" href={post.acf.url_address}> <span dangerouslySetInnerHTML={{__html: post.content.rendered }} /></a>
          </div>
      </div>
  </article>
  );
};

export default Post;