import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HappeningNow from './components/happening_now/HappeningNow';
import TagCloud from './components/tag_cloud/TagCloud';
import ClubMap from './components/club_finder/ClubMap';
import FeaturedImageFeed from './components/featured_image/FeatuedImageFeed';

if (document.getElementById('happening-now-feed-element')) {
  const happeningNow = ReactDOM.createRoot(document.getElementById('happening-now-feed-element'));
  happeningNow.render(
    <React.StrictMode>
      <HappeningNow />
    </React.StrictMode>
);
}

if (document.getElementById('tag-cloud')) {
  const tagCloud = ReactDOM.createRoot(document.getElementById('tag-cloud'));
  tagCloud.render(
    <React.StrictMode>
      <TagCloud />
    </React.StrictMode>
  );
}

if (document.getElementById('clubs_map')) {
  console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  const clubMap = ReactDOM.createRoot(document.getElementById('clubs_map'));
  clubMap.render(
    <React.StrictMode>
      <ClubMap  />
    </React.StrictMode>
  )
}
if (document.getElementById('featured-image-rx-content')) {
  const featuredImage = ReactDOM.createRoot(document.getElementById('featured-image-rx-content'));
  featuredImage.render(
    <React.StrictMode>
      <FeaturedImageFeed  />
    </React.StrictMode>
  )
}
