// import logo from './logo.svg';
import _ from 'lodash';
import Post from './Post'
import { languageInflectTitle } from '../language_inflection';

const pageLanguage = document.documentElement.lang;

function HappeningFeed({ feedData }) {
  return (
    <>
    <div class="et_pb_module et_pb_heading et_pb_heading_0 news-sidebar et_pb_bg_layout_">
        <div class="et_pb_heading_container">
            <h1 class="et_pb_module_heading">{ languageInflectTitle("Happening Now", pageLanguage) }</h1>
        </div>
    </div>
    <div class="et_pb_module et_pb_blog_0 news-sidebar et_pb_posts et_pb_bg_layout_light ">
      <div class="et_pb_ajax_pagination_container">
      {_.map(feedData, (post) => (
        <Post post={post} />
      ))}
      </div>
  </div>
  </>
  )
}

export default HappeningFeed;
