import React, { useState, useEffect } from 'react';
import ActualMap from './ActualMap';
import SearchBar from './SearchBar';
import getBaseMyURL from '../MyFFIURLManager';

const ClubMap = () => {
  const [clubsData, setClubsData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("north"); // Initial search term

  const changeSearchQuery = (searchTerm) => {
    setSearchQuery(searchTerm); // Update search query state
  };

  useEffect(() => {
    const fetchClubs = async () => {
      const url = `${getBaseMyURL()}clubs?q=${searchQuery}`;
      
      try {
        const response = await fetch(url);
        const data = await response.json();
        setClubsData(data);
      } catch (error) {
        console.error("Error fetching clubs:", error);
      }
    };

    fetchClubs();
  }, [searchQuery]); // Add `searchQuery` as a dependency

  if (!clubsData) {
    return <div class="react-loading-notice">Loading Clubs...</div>;
  }

  return (
    <>
    <SearchBar searchMethod={changeSearchQuery}/>
    <ActualMap clubs={clubsData} />
    </>
  )
};

export default ClubMap;