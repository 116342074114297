import React from 'react';
import {APIProvider, Map} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import ClubMarker from './ClubMarker';

  
const ClubMap = ( {clubs} ) => {

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div style={{ width: '100%', height: '500px' }}>
      <Map
        mapId={'5b851ec8519c3de3'}
        style={{width: '100%', height: '100%'}}
        defaultCenter={{lat: 22.54992, lng: 0}}
        defaultZoom={2}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      />
      {_.map(clubs, (c) => (
         <ClubMarker
          lat={c.lat}
          long={c.long}
          title={c.romanized_display_name}
          description={c.about_the_club}
         />
      ))}
      </div>
    </APIProvider>
  )
};

export default ClubMap;