import React from 'react';

const Tag = ({ tag }) => {
    return (
      <div className="tag-cloud-item" id="tag-{tag.id}">
        <a href={tag.link}><h5>{tag.name}</h5></a>
      </div>
  );
};

export default Tag;